import { LitElement, html, css } from "lit";

class BeamNotificationBlip extends LitElement {
  static get styles() {
    return css`
      :host {
      }

      .notification-blip {
        background-color: var(--beam-notificationBlip-color-background, #000);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    `;
  }

  render() {
    return html`<div
      class="notification-blip-container"
      part="notification-blip-container"
      aria-label="Notification Blip"
    >
      <span class="notification-blip" part="notification-blip" role="button" tabindex="0" aria-hidden="true"></span>
    </div>`;
  }
}

customElements.get("beam-notification-blip") || customElements.define("beam-notification-blip", BeamNotificationBlip);

export const notificationBlipConfigDefaults = {
  "--beam-notificationBlip-color-background": "#000",
} as const;

declare global {
  interface HTMLElementTagNameMap {
    "beam-notification-blip": BeamNotificationBlip;
  }
}
